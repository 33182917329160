import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRoutes } from '../config/api-routes';
import 'rxjs/Rx';
import { Storage } from '../utilities/storage';
import { Location } from '../../../../node_modules/@angular/common';
import { Helper } from '../utilities/helpers';

 
@Injectable()
export class PackageService {

    constructor(
        private http: HttpClient,
        private apiRoutes: ApiRoutes,
        public storage: Storage,
        public location: Location
    ) {  }
 
    getAllPackages(params = {}) {
        let url = this.apiRoutes.getAllPackagesUrl + Helper.getQueryParamsString(params);
        return this.http.get(url)
        .map((res:any) => {
            return res.data;
        });
    }
}