import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRoutes } from '../config/api-routes';
import 'rxjs/Rx';
import { Helper } from '../utilities/helpers';
import { Storage } from '../utilities/storage';
import { AuthenticationService } from './authentication.service';


 
@Injectable()
export class ProgramCategoryService {
    public currentUser: any;
    public currentOwner: any;

    constructor(
        private http: HttpClient,
        private apiRoutes: ApiRoutes,
        public storage: Storage,
        public authService: AuthenticationService
    ) { 
        this.currentUser = this.authService.authUser;
        this.currentOwner = Helper.getObjProp(this.currentUser, 'owner');
     }

    getAllProgramCategries(params = {}) {
        let url = this.apiRoutes.getAllProgramCategoriesUrl(this.currentUser.id) + Helper.getQueryParamsString(params);
        return this.http.get(url)
        .map((res:any) => {
            return res;
        });
    }
    addProgramCategory(form) {
        let url = this.apiRoutes.getAddProgramCategoriesUrl(this.currentUser.id);
        return this.http.post(url, form)
        .map((res:any) => {
            return res;
        });
    }
    getProgramCategory(programCategoryId){
        let url = this.apiRoutes.getProgramCategoryByIdUrl(programCategoryId, this.currentUser.id);
        return this.http.get(url)
        .map((res:any) => {
            return res;
        });
    }
    updateProgramCategory(programCategoryId,form) {
        let url = this.apiRoutes.getUpdateProgramCategoryByIdUrl(programCategoryId, this.currentUser.id);
        return this.http.put(url, form)
        .map((res:any) => {
            return res;
        });
    }

}