import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ApiRoutes } from './api-routes';
import * as _ from 'lodash';

@Injectable()
export class App {

    constructor(
        private titleService: Title,
        private apiRoutes: ApiRoutes,
    ) { }

    public setTitle(newTitle: string) {
        this.titleService.setTitle(newTitle);
    }

    get getApiRoutes() {
        return this.apiRoutes;
    }

}
